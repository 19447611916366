import type { OnProgress } from ".";
import { User } from "../resources";
import type { UserEntity } from "../types";

export async function getAllUsers(
  ids: string[],
  tokenGetter: () => Promise<string>,
  onProgress?: OnProgress,
) {
  const allUsers: UserEntity[] = [];
  const limit = 100;
  const dedupedIds = ids.dedupe();
  let offset = 0;

  while (true) {
    const users = await User.search(
      {
        ids: dedupedIds.slice(offset, offset + limit),
        limit: limit,
      },
      await tokenGetter(),
    );

    allUsers.push(...users.data);
    onProgress?.(Math.min(1, (offset + limit) / dedupedIds.length));

    if (offset + limit >= dedupedIds.length) {
      break;
    }

    offset += limit;
  }

  return allUsers;
}
